import React from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

import { PageMeta } from '../components/pageMeta';
import BlockZone from '../components/blockZone/zone.development';

import { useScrollContext } from '../components/layout';

const IndexPage = ({ data: { page } }) => {
  const { title, meta, pageBlocks } = page || {};
  const { scrollDefined } = useScrollContext();
  return (
    <>
      <PageMeta {...meta} />
      {scrollDefined && <>{pageBlocks && <BlockZone {...pageBlocks} />}</>}
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
